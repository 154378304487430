<template>
  <div>
         <div class="search">
        <div class="title">入园详情列表</div>
        <hr />
      <el-form :inline="true"  class="top">
        <el-form-item label="姓名:">
            <el-input v-model="params.trueName" ></el-input>
        </el-form-item>
        <el-form-item label="所在区域:">
            <el-input v-model="params.cityName" ></el-input>
        </el-form-item>
        <el-form-item label="手机号:">
            <el-input v-model="params.phone" ></el-input>
        </el-form-item>
        <el-form-item label="体温:">
            <el-select v-model="params.temperature" placeholder="请选择">
                <el-option
                v-for="item in temperatureList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
                </el-option>
            </el-select>
        </el-form-item>
            <el-form-item label="时间:">
                 <el-date-picker
                    :editable='false'
                    :picker-options="pickerOptions0"
                    value-format='yyyy-MM-dd HH:mm:ss'
                    v-model="date"
                    type="datetimerange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                </el-date-picker>
             </el-form-item>
         <el-form-item >
            <el-button type="primary"  @click="getList">查询</el-button>
            <el-button type="primary"   @click="reset">重置</el-button>
            <el-button type="primary"   @click="createForm">生成报表</el-button>
             <el-button type="primary"   @click="$router.go(-1)">返回</el-button>
        </el-form-item>
      
        </el-form>
     </div>
        <el-table
        :data="tableData"
        border
        style="width: 100%"
        align="center">
         <!-- <el-table-column type="selection" width="55"  align="center"> </el-table-column> -->
        <el-table-column type="index" width="50"   align="center"> </el-table-column>
        <el-table-column
        prop="parkName"
        label="登记地点"
        
        align="center">
        </el-table-column>
        <el-table-column
        prop="addTime"
        label="登记时间"
        
        align="center">
        </el-table-column>
   
        <!-- <el-table-column
        label="场所码"
        width="180"
        align="center">
        <template slot-scope="scope">
            <el-image 
                style="width: 70px; height: 70px"
                :src="scope.row.erCode" 
                :preview-src-list="[scope.row.erCode]">
            </el-image>
        </template>
        </el-table-column> -->
         <el-table-column
        prop="trueName"
        label="姓名"
        width="200"
        align="center">
        </el-table-column>
         <el-table-column
        prop="phone"
        label="手机号"
        width="200"
        align="center">
        </el-table-column>
         <el-table-column
        prop="totalCount"
        label="总人数"
         width="120"
        align="center">
        </el-table-column>
         <el-table-column
        prop="adultCount"
        label="成人"
        width="100"
        align="center">
        </el-table-column>
         <el-table-column
        prop="childCount"
        label="儿童"
        width="100"
        align="center">
        </el-table-column>
         <el-table-column
        prop="homeAdd"
        label="居住区域"
       
        align="center">
        </el-table-column>
         <el-table-column
        prop="temperature"
        label="体温"
        width="180"
        align="center">
        </el-table-column>
        <!-- <el-table-column
        label="操作"
        align="center"
        >
        <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="record(scope.row)">兑换记录</el-button>
            <el-button type="primary"  size="mini" @click="remove(scope.row)">删除</el-button>
            <el-button type="primary"  size="mini" @click="edit(scope.row)">编辑</el-button>
            <el-button type="primary"  size="mini" @click="code(scope.row)">生成小程序码</el-button>
            <el-button type="primary"  size="mini" @click="appoint(scope.row)">指定发放</el-button>
        </template>
        </el-table-column> -->
    </el-table>
         <div class="block">
         <!-- <div>
              <el-button type="primary" @click="allCheckVip">批量修改vip</el-button>
            <el-button type="primary" @click="allOpe">批量操作</el-button>
         </div> -->
        <div>
              <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="params.currentPage"
            :page-sizes="[5,10,20]"
            :page-size="params.pageSize"
            layout="total,sizes,prev,pager,next, jumper"
            :total="total"
        >
        </el-pagination>
        </div>
     </div>
  </div>
</template>

<script>
import { regListAll,downLoadList } from '../../api/administrator.js'
export default {
    data() {
        return {
            total: 0,
            params: {
                currentPage: 1,
                pageSize: 5,
                parkId: 0,
                trueName:'',
                phone: '',
                beginTime: '',
                endTime: '',
                temperature:0,
                cityName:'',
            },
            temperatureList:[
                {
                    value:0,
                    label:"全部"
                },
                {
                    value:1,
                    label:"全部正常体温（低于37.3℃"
                },
                {
                    value:2,
                    label:"有异常体温（高于37.3℃）"
                },

            ],
            tableData: [],
            date: null,
            pickerOptions0: {
            disabledDate(time) {
                return time.getTime() > Date.now() - 8.64e6
            },
         }
        }
    },
    created(){
      
        this.params.parkId = this.$route.query.id || 0
          this.getList()
    },
    methods: {
       
        //下载报表
        async createForm(){
        let date = +new Date();
        const { data } = await downLoadList(this.params);
        let url = window.URL.createObjectURL(data)//转换文件流为URL
        let link = document.createElement("a");
        link.style.display = "none";
        link.href = url;
        link.setAttribute("download", `万旅网小程序V2.0登记列表报表_${date}.xls`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        },
        async getList(){
            this.params.beginTime = this.date ?  this.date[0] : ''
            this.params.endTime = this.date ? this.date[1] : ''
            const {data} = await regListAll(this.params)
            if(data.code == 0){
                this.tableData = data.list
                this.total = data.pagination.total
            }else{
                this.message.warning(data.msg)
            }
        },
          handleSizeChange(e){
            this.params.pageSize = e
            this.getList()
        },
          // 页数改变
        handleCurrentChange(e){
            this.params.currentPage = e
            this.getList()
        },
        reset(){
            
            this.params.currentPage = 1
            this.params.pageSize = 5
            this.params.phone = ''
            this.params.beginTime = ''
            this.params.endTime = ''
            this.params.temperature = 0
            this.params.trueName = ''
            this.params.cityName = ''
            // this.params.parkId = 
            this.date = null
            this.getList()
        },
    }
}
</script>

<style scoped>
.title {
  padding: 10px 0;
}
.top{
    margin-top: 20px;
}
.block{
    margin-top: 15px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
}
</style>